module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.14.0_jiti@1_zpoo5sbzbciwnerhr32n7mrj6m/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NZXXSQC","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.14.0_jiti@1.21.6___react-dom@18.3.1_react@18.3.1___ezc6bnvdulwt4syopmizi2mzgu/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
